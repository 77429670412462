<template>
  <mds-page-shell
    :logo="require('@/assets/logos/morningstar.svg')"
    :logo-responsive="require('@/assets/logos/morningstar.svg')"
    logo-href="/"
    class="dap"
    logo-alt="Back to home page"
  >
    <template #mds-page-shell-masthead-right>
      <div class="rigth-content">
        <env-tag />
        <mds-button
          variation="flat"
          icon="person"
          flat-button-icon-size="medium"
          @click="logout"
        >
          Sign Out
        </mds-button>
      </div>
    </template>
    <template #mds-page-shell-horizontal-nav>
      <site-navigation :tabs-site-nav-content="tabsSiteNavContent" />
    </template>
    <template #default>
      <router-view class="dap__container" />
    </template>
  </mds-page-shell>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsPageShell from '@mds/page-shell';
import SiteNavigation from '@/components/layout/SiteNavigation';
import { MODULE } from '@/constants/store.constant';
import AUTH from '@/constants/auth.constant';
import ROUTES from '@/constants/routes.constant';
import EnvTag from '@/components/ui/EnvironmentTag.vue';

export default {
  name: 'PageShell',
  components: {
    MdsPageShell,
    SiteNavigation,
    MdsButton,
    EnvTag,
  },
  data () {
    return {
      tabsSiteNavContent: [
        {
          text: 'Providers',
          id: ROUTES.PROVIDERS,
          active: false,
        },
        {
          text: 'Collection',
          id: ROUTES.COLLECTION,
          active: true,
        },
        {
          text: 'Snapshots',
          id: ROUTES.PREC,
          active: true,
        }
      ],
    };
  },
  methods: {
    logout () {
      this.$store.dispatch(`${MODULE.AUTH}/${AUTH.LOG_OUT}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dap {
  background-color: $mds-background-color-light-gray;
  &__container {
    background-color: $mds-background-color-white;
    border: $mds-border-separator;
    border-radius: $mds-border-radius-panel;
    min-height: 100vh;
    margin: $mds-space-4-x auto;
    padding: $mds-space-4-x !important;
  }
}
.rigth-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between
}
::v-deep .mds-layout-grid__row__dap-crawler {
  margin-bottom: $mds-space-3-x;
}
::v-deep thead {
  text-align: left;
}
</style>
